$(`.page-header__makes-btn`).on('click', () => {
  if ($(`.main-nav__toggle`).is(`.main-nav__toggle--active`)) {
    $(`body`).removeClass(`body--fixed`)
    $(`body`).addClass(`body--fixed`)
  } else {
    $(`body`).toggleClass(`body--fixed`)
  }

  $(`.page-header__makes-btn`).toggleClass(`page-header__makes-btn--active`);
  $(`.main-nav`).removeClass(`main-nav--active`);
  $(`.main-nav__toggle`).removeClass(`main-nav__toggle--active`);
  $(`.overlay`).removeClass(`overlay--active`);
  $(`.modal-search`).removeClass(`modal-search--active`);

  $(`.modal-makes`).toggleClass(`modal-makes--active`);

  $(`footer`).toggleClass(`visually-hidden`);
});
