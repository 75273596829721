//  Перенос блока атвотеки в TAB 'Проверка VIN' на мобилке
if ($(window).width() <= 768) {
    let autoteka_block = $('.car-autoteka ');
    let vin_tab_contant = $('.car__info-block--vin');
    vin_tab_contant.html(autoteka_block);


    // Развернуть данные автотеки
    $('.car-autoteka-block-title').on('click', function() {
        if ($(this).hasClass('car-autoteka-block-title--onlytitle')) {
            return false
        }
        if ($(this).hasClass('car-autoteka-block-title--active')) {
            $(this).removeClass('car-autoteka-block-title--active')
            $(this).closest('.car-autoteka-block').find($('.car-autoteka-block-text')).slideUp();
        } else {
            $(this).addClass('car-autoteka-block-title--active')
            $(this).closest('.car-autoteka-block').find($('.car-autoteka-block-text')).slideDown();
        }

    })
}