import "jquery";

const lazyPicture = document.querySelector('.lazyload');
const selectTwo = document.querySelector('.select');
const sliderCar = document.querySelector('.slider-car');
const rangeSlider = document.querySelector('.form__wrap--range');
const popupLink = document.querySelector('.popup--link');
const barRating = document.querySelector('.review__bar');
const calendar = document.querySelector('.calendar');
const facts = document.querySelector('.facts');
const map = document.querySelector('.map');

if (lazyPicture) {
  import(/* webpackChunkName: "lazysizes" */ 'lazysizes').then(module => module.init());
}

if (map) {
  import(/* webpackChunkName: "map" */ './components/ymaps').then(module => module.init());
}

if (facts) {
  import(/* webpackChunkName: "facts" */ './components/counter').then(module => module.init());
}

if (selectTwo) {
  import(/* webpackChunkName: "select" */ './components/select.js').then(module => module.init());
}

if (sliderCar) {
  import(/* webpackChunkName: "fancybox" */ './components/fancybox.js').then(module => module.init());
}

if (rangeSlider) {
  import(/* webpackChunkName: "range" */ './components/range.js').then(module => module.init());
}

if (popupLink) {
  import(/* webpackChunkName: "popup" */ './components/popup.js').then(module => module.init());
}

if (barRating) {
  import(/* webpackChunkName: "rating" */ './components/rating.js').then(module => module.init());
}

if (calendar) {
  import(/* webpackChunkName: "calendar" */ './components/flatpickr.js').then(module => module.init());
}

import "slick-carousel";
import "jquery.maskedinput/src/jquery.maskedinput.js";
// import "brazzers-carousel/Brazzers-Carousel/jQuery.Brazzers-Carousel";

import "./components/tippy.js";
import "./components/slider.js";
import "./components/selection.js";
import "./components/sorting-view.js";
import "./components/autoteka.js";
import "./components/footer.js";
import "./components/mask.js";
import "./components/form.js";
import "./components/header.js";
import "./components/main-nav.js";
import "./components/modal-makes.js";
import "./components/car.js";
import "./components/makes.js";
import "./components/tech.js";
import "./components/scroll.js";
import "./components/faq.js";
import "./components/review.js";
import "./components/youtube.js";
import "./components/img.js";

import "./components/search.js";
