if (window.matchMedia(`(max-width: 767px)`).matches) {
  $(`.car__buy-options`).appendTo(`.car__presentation`);
}

if (window.matchMedia(`(max-width: 767px)`).matches) {
  $(`.car__detail-block-wrap`).insertAfter(`.car__slider`);
}

if (window.matchMedia(`(max-width: 767px)`).matches) {
  $(`.car__express-form`).insertAfter(`.car__detail-block-wrap`);
}

$(`.car__info-tab`).on('click', function () {
  const tabs = $(`.car__info-tab`);
  const cont = $(`.car__info-block`);

  tabs.removeClass(`car__info-tab--active`);
  cont.removeClass(`car__info-block--active`);

  $(this).addClass(`car__info-tab--active`);
  cont.eq($(this).index()).addClass(`car__info-block--active`);

  return false;
});

$(`.car__phone-mock`).on('click', function () {
  $(this).remove();
});

$(`.catalog__car-phone-wrap`).on('click', function () {
  $(this).children(`.catalog__car-phone-mock`).remove();
});
